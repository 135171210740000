import * as THREE from 'three'
import Entity from "./Entity";
import {Float32BufferAttribute} from "three";
import {setNormals} from "../Utils/Utils";
import GroundMaterial from "../Materials/GroundMaterial";

export default class Plain extends Entity
{
    params
    points = []
    vertices  = [];
    indices = [];
    uvs = [];
    normals = [];
    maxVal = null;
    minVal = null;

    constructor(params = {
        width: 100,
        depth: 100,
        widthSegments: 225,
        depthSegments: 225,
    })
    {
        super();
        this.params = params;
        this.width_half = this.params.width /2
        this.depth_half = this.params.depth /2
        this.gridX = Math.floor( this.params.widthSegments );
        this.gridZ = Math.floor( this.params.depthSegments );
        this.gridX1 = this.gridX +1;
        this.gridZ1 =  this.gridZ +1;
        this.segment_width = this.params.width / this.gridX;
        this.segment_depth = this.params.depth / this.gridZ;
        this.setModel()
        this.mesh.receiveShadow = true;

    }

    calculateX(column) {
        column += 4564
        return Math.sin(column*.25)+ Math.sin(column*.25 * 4)/4  + Math.sin(column*.25 * 16)/16  // Par exemple, une plane de -1 à 1 sur l'axe X
    }

    calculateZ(row) {
        row += 632
        return  (Math.cos(row*.05)   + Math.cos(row*.05 * 4)/4  + Math.cos(row*.05 * 16)/16)
    }

    calculateVertices(x,z){
        const y = this.calculateX(x) * this.calculateZ(-z)  + this.noise.simplex2(x*0.025,-z*0.025);
        if(!this.minVal && !this.maxVal){
            this.minVal = y;
            this.maxVal = y;
        }
        else{
            this.minVal = Math.min(this.minVal,y);
            this.maxVal = Math.max(this.maxVal,y);
        }

        this.vertices.push( x, y , -z );
        this.points.push( [x, y , -z] );
    }

    setVertex(){
        for ( let iz = 0; iz < this.gridZ1; iz ++ ) {

            const z = iz * this.segment_depth - this.depth_half;

            for ( let ix = 0; ix < this.gridX1; ix ++ ) {

                const x = ix * this.segment_width - this.width_half;

                this.calculateVertices(x,z)

                this.uvs.push( ix / this.gridX );
                this.uvs.push( 1 - ( iz / this.gridZ ) );
            }

        }

        for ( let iz = 0; iz < this.gridZ; iz ++ ) {

            for ( let ix = 0; ix < this.gridX; ix ++ ) {

                const a = ix + this.gridX1 * iz;
                const b = ix + this.gridX1 * ( iz + 1 );
                const c = ( ix + 1 ) + this.gridX1 * ( iz + 1 );
                const d = ( ix + 1 ) + this.gridX1 * iz;

                this.indices.push( a, b, d );
                    this.indices.push( b, c, d );

            }

        }

        this.normals = setNormals(this.indices,this.vertices)
    }

    setModel()
    {
        this.initRandom()
        this.setVertex()
        this.geometry = new THREE.BufferGeometry();
        this.geometry.setIndex(this.indices)
        this.geometry.setAttribute( 'position', new Float32BufferAttribute( this.vertices, 3 ) );
        this.geometry.setAttribute( 'normal', new Float32BufferAttribute( this.normals, 3 ) );
        this.geometry.setAttribute( 'uv', new Float32BufferAttribute( this.uvs, 2 ) );

        this.material = new GroundMaterial({
            color: "#C5340F",
            side: THREE.DoubleSide
        },this.time,{
            uMinVal: this.minVal,
            uMaxVal: this.maxVal,
            uGrassColor: this.world.color1,
            uSandColor: this.world.color2,
            uHumidity: this.world.humidity,
            uPattern: new THREE.CanvasTexture(this.world.canvasSetup.canvases["ground"].cvs),
            uResolution: new THREE.Vector2( this.sizes.viewport.width, this.sizes.viewport.height )
        });
        this.mesh = new THREE.Mesh(this.geometry, this.material);
        this.scene.add(this.mesh);

    }

    extractCoord(width,depth){

        return new THREE.Vector3(width, this.calculateX(width) * this.calculateZ(-depth)  + this.noise.simplex2(width*0.025,-depth*0.025), -depth );
    }

    initRandom(){

    }
    update()
    {
        // if(this.material){
        //     this.material.update()
        // }
    }
}