import * as THREE from 'three'
import Entity from "./Entity";
import LeafMaterial from "../Materials/LeafMaterial";
import RockMaterial from "../Materials/RockMaterial";
import LanternMaterial from "../Materials/LanternMaterial";

export default class Lantern extends Entity
{
    static idx = 0;

    constructor()
    {
        super();
        this.resource = this.resources.items.lantern

        this.setModel()

    }

    setModel(){
        this.selectedPoints = this.getPointsInThreshold()
        const nbPoints = this.selectedPoints[0].length

        if(this.selectedPoints[0].length > 0){
            const dummy = new THREE.Object3D();

            this.model = this.resource.scene

            this.model.traverse((child,i) =>
            {
                if(child instanceof THREE.Mesh)
                {
                    this.geometry = (child.geometry)
                }
            })

            this.material = new LanternMaterial({color:"#702713"},this.time,
                {
                    uGrassColor:this.world.color1,
                    uSandColor: new THREE.Color("#4b3128"),
                    uHumidity: this.world.humidity,
                    uPattern: new THREE.CanvasTexture(this.world.canvasSetup.canvases["lantern"].cvs),
                    uResolution: new THREE.Vector2( this.sizes.viewport.width, this.sizes.viewport.height )

                });



            // this.material[1].depthTest = true
            // this.material[1].depthWrite = true
            this.mesh = new THREE.InstancedMesh(this.geometry, this.material,nbPoints)

            for(let i = 0; i < nbPoints; i++){
                dummy.position.set(...this.selectedPoints[0][i])
                const rScale = this.prng() * (0.035-0.03) + .03
                dummy.scale.set(rScale,rScale,rScale)
                // dummy.lookAt(this.camera.position)
                dummy.rotation.x = -Math.PI * 0.5
                dummy.rotation.z = -Math.PI * this.prng()
                
                dummy.updateMatrix()
                this.mesh.setMatrixAt(i, dummy.matrix)
            }
            this.scene.add(this.mesh);


        }

    }

    getPointsInThreshold(){
        let vertices = [...this.world.plain.points];
        const threshold = this.prng() * (0.8-0.7) + 0.7;
        vertices =  vertices.filter(point => {
            return (this.calcThreshold(point[1]) < threshold
                && this.calcThreshold(point[1]) > threshold-0.2
                && this.prng() > 0.995 && point[2] > -36.5)
        } )

        const newVertices = [];
        const newVerticesDest = [];

        const amount = Math.ceil(this.prng() * 10)

        for(let i = 0; i < amount;i++){
            const index = Math.ceil(this.prng() * (vertices.length-1))
            if(vertices.length > 0){
                const newX = vertices[index][0] + (this.prng()-.5) *.35
                const newZ = vertices[index][2] + (this.prng()-.5) *.35
                const extractCoords = this.world.plain.extractCoord(newX, newZ);
                extractCoords.y -= .1;

                newVertices.push(extractCoords)
                newVerticesDest.push(...extractCoords.toArray())
            }

        }
        return [newVertices,newVerticesDest]
    }

    calcThreshold(y){
        return (y - this.world.plain.minVal)/this.world.plain.maxVal;
    }


    update()
    {

    }
}