import * as THREE from 'three'
import Entity from "./Entity";
import LeafMaterial from "../Materials/LeafMaterial";

export default class Bamboo extends Entity
{
    static idx = 0;

    constructor()
    {
        super();
        this.resource = this.resources.items.bambooBush

        this.setModel()

    }

    setModel(){
        this.selectedPoints = this.getPointsInThreshold()
        const nbPoints = this.selectedPoints[0].length

        if(this.selectedPoints[0].length > 0){
            const dummy = new THREE.Object3D();

            this.model = this.resource.scene
            this.material = []
            this.geometry = []

            this.model.traverse((child,i) =>
            {
                if(child instanceof THREE.Mesh)
                {
                    this.material.push(child.material)
                    this.geometry.push(child.geometry)
                }
            })

            this.leafText = this.material[0].map

            this.material[0] = new LeafMaterial({
                color: this.world.color1,
                transparent:true,

            },{
                uTexture : this.leafText,
                uColor: this.world.color1,
                uResolution: new THREE.Vector2( this.sizes.viewport.width, this.sizes.viewport.height ),
                uPattern: new THREE.CanvasTexture(this.world.canvasSetup.canvases["leaf"].cvs),
            })

            const trunkColor = this.world.color1.clone();
            trunkColor.r *= this.world.humidity - .15
            trunkColor.g *= this.world.humidity + .15
            trunkColor.b *= this.world.humidity - .15


            this.material[1] = new THREE.MeshToonMaterial({
                color: trunkColor,
                transparent:true
            })

            // this.material[1].depthTest = true
            // this.material[1].depthWrite = true
            this.mesh = new THREE.InstancedMesh(this.geometry[0], this.material[0],nbPoints)
            this.mesh2 = new THREE.InstancedMesh(this.geometry[1], this.material[1],nbPoints)

            for(let i = 0; i < nbPoints; i++){
                dummy.position.set(...this.selectedPoints[0][i])
                const rScale = this.prng() * (0.15-0.05) + .05
                dummy.scale.set(rScale,rScale,rScale)
                // dummy.lookAt(this.camera.position)
                dummy.rotation.x = -Math.PI * 0.5
                dummy.rotation.z = -Math.PI * this.prng()
                dummy.updateMatrix()
                this.mesh.setMatrixAt(i, dummy.matrix)
                this.mesh2.setMatrixAt(i, dummy.matrix)
            }
            this.scene.add(this.mesh);
            this.scene.add(this.mesh2);


        }

    }

    getPointsInThreshold(){
        let vertices = [...this.world.plain.points];
        const threshold = this.prng() * (1.25-1.) + 1.;
        vertices =  vertices.filter(point => {
            return (this.calcThreshold(point[1]) > threshold && this.prng() > 0.975 && point[2] > -36.5)
        } )

        const newVertices = [];
        const newVerticesDest = [];

        for(let i = 0; i < vertices.length;i++){
            const newX = vertices[i][0] + (this.prng()-.5) *.35
            const newZ = vertices[i][2] + (this.prng()-.5) *.35
            newVertices.push(this.world.plain.extractCoord(newX, newZ))
            newVerticesDest.push(...this.world.plain.extractCoord(newX, newZ).toArray())
        }
        return [newVertices,newVerticesDest]
    }

    calcThreshold(y){
        return (y - this.world.plain.minVal)*this.world.humidity/this.world.plain.maxVal;
    }


    update()
    {

    }
}