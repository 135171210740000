import * as THREE from 'three'
import { Float32BufferAttribute } from "three";
import Plain from "./Plain";
import MountainMaterial from "../Materials/MountainMaterial";

export default class Mountain extends Plain {
    static idx = 0
    static ecartType = null;
    static flatThreshold = null;
    static mountains = [];
    static bigMountain = false;
    constructor(params = {
        width: 150 + Mountain.idx * 25,
        depth: 50,
        widthSegments: 100,
        depthSegments: 100,
    })
    {

        super(params);

        this.mesh.position.set(0, this.yPos, this.zPos)
        this.mesh.material.color = new THREE.Color("#702713")

        this.maskMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff })

        Mountain.mountains.push(this);
    }

    setMaterial(name) {
        this.mesh.material = this[name];
    }

    clamp(x, minVal, maxVal) {
        return Math.min(Math.max(x, minVal), maxVal);
    }

    calculateX(column) {
        column += this.offsetX
        column /= this.widthMount

        const exposant = -((column * column) / (2 * Mountain.ecartType * Mountain.ecartType));
        let hauteur = this.heightMount * Math.exp(exposant * 0.01);
        hauteur += this.noise.simplex2(column * this.zRelief, exposant * this.zRelief) * .1;
        hauteur = this.clamp(hauteur, 0, this.heightMount - 1.5 * Mountain.flatThreshold)
        hauteur += this.noise.simplex2(column * this.zRelief, column * this.zRelief) * 0.01;

        return hauteur
    }

    calculateZ(row) {
        row += 15
        row /= this.widthMount * 1.5
        const exposant = -((row * row) / (2 * Mountain.ecartType / 2 * Mountain.ecartType / 2));
        let hauteur = this.heightMount * Math.exp(exposant * 0.01);
        hauteur += Math.sin(row * 4) / 4 * 0.01;
        hauteur += this.noise.simplex2(row * this.zRelief, row * this.zRelief);
        hauteur += this.noise.simplex2(row * this.zRelief, row * this.zRelief);

        return hauteur

    }
    calculateVertices(x, z) {
        const y = this.calculateX(x) * this.calculateZ(-z);
        if(!this.minVal && !this.maxVal){
            this.minVal = y;
            this.maxVal = y;
        }
        else{
            this.minVal = Math.min(this.minVal,y);
            this.maxVal = Math.max(this.maxVal,y);
        }
        this.vertices.push( x,y , -z );
    }

    setModel() {
        this.initRandom()
        this.setVertex()
        this.geometry = new THREE.BufferGeometry();
        this.geometry.setIndex(this.indices)
        this.geometry.setAttribute('position', new Float32BufferAttribute(this.vertices, 3));
        this.geometry.setAttribute('normal', new Float32BufferAttribute(this.normals, 3));
        this.geometry.setAttribute('uv', new Float32BufferAttribute(this.uvs, 2));

        this.material = new MountainMaterial({
            color: "#C5340F",
            side: THREE.DoubleSide
        }, this.time, {
            uMinVal: this.minVal,
            uMaxVal: this.maxVal,
            uGrassColor: this.world.color2,
            uSandColor: this.world.color5,
            uHumidity: this.world.humidity,
            uPattern: new THREE.CanvasTexture(this.world.canvasSetup.canvases["mountain"].cvs),
            uResolution: new THREE.Vector2( this.sizes.viewport.width, this.sizes.viewport.height )
        });
        this.mesh = new THREE.Mesh(this.geometry, this.material);
        this.scene.add(this.mesh);

    }

    initRandom() {
        Mountain.idx += 1;

        const min = -55 - 5 * Mountain.idx;
        const max = 55 + 5 * Mountain.idx;
        this.offsetX = this.prng() * (max - min) + min;
        // this.offsetX = 0

        const minHeight = 4
        const maxHeight = 6
        this.heightMount = this.prng() * (maxHeight - minHeight) + minHeight
        // this.heightMount = 5

        if (!Mountain.ecartType) {
            const minEc = 0.15
            const maxEc = .35
            Mountain.ecartType = this.prng() * (maxEc - minEc) + minEc
        }
        // this.ecartType = .5

        if (!Mountain.flatThreshold) {
            Mountain.flatThreshold = this.prng()
        }

        // this.flatThreshold = 0
        const minW = 1.5
        const maxW = 6
        this.widthMount = this.prng() * (maxW - minW) + minW

        const minR = 0.15;
        const maxR = .5
        this.zRelief = this.prng() * (maxR - minR) + minR


        this.zPos = -75 * Mountain.idx
        this.yPos = -7.5 - 2.5 * Mountain.idx

        if (Mountain.bigMountain) {
            this.offsetX = 0
            this.heightMount = this.prng() * (7.5 - 6.5) + 6.5
            if (Mountain.idx == 1) {
                this.heightMount = this.prng() * (6.5 - 6) + 6
            }

            Mountain.flatThreshold = 1
            Mountain.ecartType = .6
            this.yPos = -7.5-5*Mountain.idx

        }

    }

    update(){
        // if(localStorage.getItem('image') !== this.imageData ){
        //     const imageDataUrl = localStorage.getItem('image');
        //     this.imageData = imageDataUrl
        //     if (imageDataUrl) {
        //         const img = new Image();
        //         img.src = imageDataUrl;
        //         img.onload = () => {
        //             const texture = new THREE.Texture(img);
        //             texture.needsUpdate = true;
        //         }
        //     }
        //     this.img = new THREE.TextureLoader().load(imageDataUrl);
        //     this.material.updateImg(imageDataUrl)
        //     console.log("tru")
        // }
    }
}

// -abs((sin((x+noise(x*.2)*12.5))*sin(x*4/4) + sin(x*4/4)+ sin(x * 16)/16 + noise(x*.25) ) +.75) +3  + noise(0.5*x) + noise(0.2*x)