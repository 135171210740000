import { log } from "three/examples/jsm/nodes/Nodes";
import Entity from "./Entity";
import p5 from "p5";
import Chalkboard from "./Chalkboard";





let width = 1000;
let height = 1000;
let chalkboard;
let p5canvas;
let _p;

export default class CanvasSetup extends Entity {

    constructor() {
        super()


        this.p5 = new p5(p => {
            _p = p
            p.setup = () => this.setup(p)
            // p.draw = () => this.draw(p)
        })

        this.canvasesId = ['mountain', 'ground', 'grass', 'house', 'lantern',"leaf","rock","sky","sun"]
        this.canvases = {}

        // setup cache canvases
        for (let i = 0; i < this.canvasesId.length; i++) {
            const id = this.canvasesId[i]
            this.makeCanvas(id)
        }

        // draw cache canvases
        for (let i = 0; i < this.canvasesId.length; i++) {
            const id = this.canvasesId[i]
            this.draw(id)
        }


    }

    makeCanvas(id) {
        const cvs = document.createElement('canvas')
        const ctx = cvs.getContext('2d')

        cvs.width = this.sizes.viewport.width
        cvs.height = this.sizes.viewport.height
        cvs.style.width = this.sizes.viewport.width + 'px'
        cvs.style.height = this.sizes.viewport.height + 'px'

        this.canvases[id] = {cvs, ctx}

        document.querySelector(".gridCanvas").appendChild(cvs)

    }


    setup(p) {
        p5canvas = p.createCanvas(this.sizes.viewport.width, this.sizes.viewport.height);
        p5canvas.parent('p5');
        chalkboard = new Chalkboard(this.sizes.viewport.width, this.sizes.viewport.height, p, this.world.prng);
    }

    draw(id) {
        // mountains
        const ctx = this.canvases[id].ctx
        chalkboard.draw();
        ctx.drawImage(p5canvas.elt, 0, 0)
    }



}