
export function setNormals(indices,vertices) {
    const numVertices = vertices.length / 3;
    const numTriangles = indices.length / 3;

    // Initialisation des normales à zéro
    const vertexNormals = new Array(numVertices).fill([0, 0, 0]);

    // Calcul des normales des triangles et accumulation dans les normales des sommets
    for (let i = 0; i < numTriangles; i++) {
        const ai = indices[i * 3];
        const bi = indices[i * 3 + 1];
        const ci = indices[i * 3 + 2];

        const a = [vertices[ai * 3], vertices[ai * 3 + 1], vertices[ai * 3 + 2]];
        const b = [vertices[bi * 3], vertices[bi * 3 + 1], vertices[bi * 3 + 2]];
        const c = [vertices[ci * 3], vertices[ci * 3 + 1], vertices[ci * 3 + 2]];

        const normal = computeNormal(a, b, c);

        vertexNormals[ai] = add(vertexNormals[ai], normal);
        vertexNormals[bi] = add(vertexNormals[bi], normal);
        vertexNormals[ci] = add(vertexNormals[ci], normal);
    }

    // Normalisation des normales des sommets
    for (let i = 0; i < numVertices; i++) {
        vertexNormals[i] = normalize(vertexNormals[i]);
    }

    return [].concat(...vertexNormals); // Conversion en tableau à plat
}

export function computeNormal(a, b, c) {
    const ab = subtract(b, a);
    const ac = subtract(c, a);
    return normalize(cross(ab, ac));
}

export function add(a, b) {
    return [a[0] + b[0], a[1] + b[1], a[2] + b[2]];
}

export function subtract(a, b) {
    return [a[0] - b[0], a[1] - b[1], a[2] - b[2]];
}

// Fonction utilitaire pour normaliser un vecteur
export function normalize(v) {
    const length = Math.sqrt(v[0] * v[0] + v[1] * v[1] + v[2] * v[2]);
    return [v[0] / length, v[1] / length, v[2] / length];
}

export function cross(a, b) {
    const x = a[1] * b[2] - a[2] * b[1];
    const y = a[2] * b[0] - a[0] * b[2];
    const z = a[0] * b[1] - a[1] * b[0];
    return [x, y, z];
}

export function mix(x, y, a) {
    return x * (1.0 - a) + y * a;
}