import * as THREE from 'three'
import Experience from './Experience.js'
import Plain from "./GlobalScene/Plain";
import Mountain from "./GlobalScene/Mountain";
import Sky from './GlobalScene/Sky.js';
import { Noise } from 'noisejs'
import Alea from "alea";
import CanvasSetup from "./GlobalScene/CanvasSetup";
import Grass from "./GlobalScene/Grass";
import Bamboo from "./GlobalScene/Bamboo";
import Lantern from "./GlobalScene/Lantern";
import House from "./GlobalScene/House";
import Rock from "./GlobalScene/Rock";
import LightSphere from "./GlobalScene/LightSphere";
import colorData from './GlobalScene/colors.json'

export default class World {
    constructor(_options) {
        const params = new URLSearchParams(window.location.search);
        const seed = params.get("seed");
        console.log(seed)
        if (!seed) {
            this.rand = Math.random();
        }
        else {
            this.rand = seed;
        }
        console.log(this.rand)
        this.prng = new Alea(this.rand)

        this.seed = document.getElementById('seed');
        this.seed.value = this.rand * 10000;
        this.seed.addEventListener('change', () => {

        })

        this.experience = new Experience()
        this.config = this.experience.config
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.noise = new Noise(this.rand);

        const colorNumber = Math.ceil(this.prng()*Object.keys(colorData).length)

        this.color1 = new THREE.Color(colorData[colorNumber][0]);
        this.color1.r += (this.prng() - .5) * .05
        this.color2 = new THREE.Color(colorData[colorNumber][1]);
        this.color2.r += (this.prng() - .5) * .05
        this.color3 = new THREE.Color(colorData[colorNumber][2]);
        this.color3.r += (this.prng() - .5) * .05
        this.color4 = new THREE.Color(colorData[colorNumber][3]);
        this.color4.r += (this.prng() - .5) * .05
        this.color5 = new THREE.Color(colorData[colorNumber][4]);
        this.color5.r += (this.prng() - .5) * .05

        this.humidity = this.prng();

        this.nbMountains = Math.ceil(this.prng() * 20)
        this.nbRocks = Math.ceil(this.prng() * (50 - 20) + 20)

        this.lightStrength = this.prng() * (2.5 - .25) + .25;

        this.lightPos = new THREE.Vector3();
        this.lightPos.x = this.prng() * (155 - (-155)) + (-155)
        this.lightPos.y = this.prng() * (55 - (15)) + (15)
        this.lightPos.z = this.prng() * (-600 - (-800)) + (-800);


        this.resources.on('groupEnd', (_group) => {
            if (_group.name === 'base') {
                this.setDummy()
                this.canvasSetup = new CanvasSetup()
                // this.canvasSetup.getImage()
                this.light = new THREE.DirectionalLight(this.color4, this.lightStrength * 1.25);
                this.lightSphere = new LightSphere(this.lightPos)
                this.plain = new Plain();
                for (let i = 0; i < this.nbMountains; i++) {
                    if (i == this.nbMountains - 1 && this.nbMountains < 4) {
                        Mountain.bigMountain = true;
                    }
                    let mountain = new Mountain()
                }
                for (let i = 0; i < this.nbRocks; i++) {
                    let rock = new Rock()
                }
                this.bamboo = new Bamboo()
                this.grass = new Grass()
                this.lantern = new Lantern()
                this.house = new House()
                this.sky = new Sky()

                this.scene.add(this.light)





                // this.setEnv()
            }
        })
    }

    setEnv() {
        this.resources.items.env.mapping = THREE.EquirectangularReflectionMapping
        this.scene.environment = this.resources.items.env
        this.scene.background = this.resources.items.env
    }

    setDummy() {
        // this.resources.items.lennaTexture.encoding = THREE.sRGBEncoding

        // const cube = new THREE.Mesh(
        //     new THREE.BoxGeometry(1, 1, 1),
        //     new THREE.MeshBasicMaterial({ map: this.resources.items.lennaTexture })
        // )
        // this.scene.add(cube)
    }

    resize() {
    }

    getRandomColor() {
        // Générer trois composantes de couleur (rouge, vert, bleu) aléatoires
        const h = this.prng() * (100 - 20) + 20; // Valeur entre 0 et 255
        const s = 40;
        const l = 11;

        const ecartTeinte = 20;

        // Formater la couleur en format hexadécimal
        const colors = [];
        const color = this.HSLToRGB(h, s, l);
        const color2 = this.HSLToRGB(h + 120, s, l);
        const color3 = this.HSLToRGB(h + 240, s, l);

        return [color, color3];
    }

    HSLToRGB = (h, s, l) => {
        s /= 100;
        l /= 100;
        const k = n => (n + h / 30) % 12;
        const a = s * Math.min(l, 1 - l);
        const f = n =>
            l - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));
        return [f(0), f(8), f(4)];
    };



    update() {
        if (this.plain) {
            this.plain.update()
        }
        if (this.grass) {
            this.grass.update()
        }
        if (Mountain.mountains.length > 0) {
            Mountain.mountains.forEach(mountain => {
                mountain.update()
            })
        }
        if (this.sky) {
            this.sky.update()
        }
        if (this.lightSphere) {
            this.lightSphere.update();
        }
    }

    destroy() {
    }
}