import * as THREE from 'three'
import Entity from "./Entity";
import RockMaterial from "../Materials/RockMaterial";

export default class Rock extends Entity
{
    static idx = 0;
    static rocks = [];

    constructor()
    {
        super();
        this.radius = this.prng() * (2-.5) + .5 ;
        this.details = this.prng() * 3 ;
        this.details = Math.ceil(this.details)
        this.geometry = new THREE.TetrahedronGeometry(this.radius ,this.details  )
        this.material = new RockMaterial({color:"#702713"},this.time,
            {
                uGrassColor:this.world.color1,
                uSandColor: this.world.color2,
                uHumidity: this.world.humidity,
                uPattern: new THREE.CanvasTexture(this.world.canvasSetup.canvases["rock"].cvs),
                uResolution: new THREE.Vector2( this.sizes.viewport.width, this.sizes.viewport.height )

            });
        this.mesh = new THREE.Mesh(this.geometry,this.material);

        const plainSize = this.world.plain.width_half;
        const posX = this.prng() * plainSize * 2 - plainSize;
        const posZ = this.prng() * plainSize * 2 - plainSize;

        const coord = this.world.plain.extractCoord(posX,posZ).toArray();
        let rotClone = this.mesh.rotation.clone();

        rotClone.x += this.prng() * Math.PI/2 - Math.PI/4
        rotClone.y += this.prng() * Math.PI*2
        rotClone.z += this.prng() * Math.PI/2- Math.PI/4

        this.mesh.rotation.x = rotClone.x
        this.mesh.rotation.y = rotClone.y
        this.mesh.rotation.z = rotClone.z

        this.mesh.position.set(coord[0],coord[1]+this.prng()*.5,coord[2]);
        this.scene.add(this.mesh);


    }


    update()
    {
        // if(this.material){
        //     this.material.update()
        // }
    }
}