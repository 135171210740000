import { Scribble } from "./Scribbles.js"

export default class Chalkboard {
    constructor(width, height, p, prng) {
        this.width = width;
        this.height = height;
        this.scribble = new Scribble(p);
        this.p = p;
        this.prng = prng;

        this.mySeed = this.prng() * 10000;

        window.addEventListener('keyup', (e) => {
            if (e.key === 's') {
                this.save();
            }
        })
    }

    createChalkboardBackground() {
        this.p.background(255);
        let bg = this.p.createGraphics(this.width, this.height);
        for (let i = 0; i < 1000; i++) {
            let x = this.p.random(this.width);
            let y = this.p.random(this.height);
            bg.stroke(0, 10);
            bg.point(x, y);
        }
        this.p.image(bg, 0, 0);
    }

    drawScribbleFilling() {
        let gap = this.p.map(this.mySeed % 5, 0, 9, 5, 5);
        let angle = this.p.map(this.mySeed % 1000, 0, 99, 0, 360);

        let xJitter = this.p.random(-2, 2);
        let yJitter = this.p.random(-2, 2);

        this.scribble.scribbleFilling(
            [0 + xJitter, 0 + xJitter, this.width + xJitter, this.width + xJitter],
            [0 + yJitter, this.height + yJitter, this.height + yJitter, 0 + yJitter],
            gap,
            angle
        );
    }

    saveBlob = (blob, fileName) => {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style.display = 'none';
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
    }

    save = () => {
        const canvas = document.getElementById('defaultCanvas0');
        canvas.toBlob((blob) => {
            this.saveBlob(blob, `sky.png`);
        });
    }

    applyStyles() {
        let bowing = this.p.map(this.mySeed % 10, 0, 99, 0, 0.3);
        let roughness = this.p.map((this.mySeed % 100) / 10, 0, 9, 1.5, 2.5);
        let col = this.p.color(
           0,
            0,
            0,
            255
        );

        this.scribble.bowing = bowing;
        this.scribble.roughness = roughness;
        this.p.stroke(col);
        this.p.strokeWeight(this.p.random(4,5));
    }

    getImage(callback) {
        // this.save()
        const myCallback = callback
        const canvas = document.getElementById('defaultCanvas0');
        canvas.toBlob((blob) => {
            const reader = new FileReader();

            reader.onloadend = () => {
                localStorage.clear();
                localStorage.setItem('image', reader.result);
            };
            reader.readAsDataURL(blob);
        });


    }


    draw() {
        this.mySeed = this.prng() * 10000;

        this.createChalkboardBackground();
        this.applyStyles();
        this.drawScribbleFilling();
    }




}





