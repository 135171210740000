import * as THREE from 'three'
import GUI from 'lil-gui'

import Time from './Utils/Time.js'
import Sizes from './Utils/Sizes.js'
import Stats from './Utils/Stats.js'

import Resources from './Resources.js'
import Renderer from './Renderer.js'
import Camera from './Camera.js'
import World from './World.js'

import assets from './assets.js'
import Mouse from "./Mouse";
import Sound from "../../public/assets/sound.mp3";

//postprocessing
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import Mountain from './GlobalScene/Mountain.js'


export default class Experience {
    static instance

    constructor(_options = {}) {
        if (Experience.instance) {
            return Experience.instance
        }
        Experience.instance = this

        // Options
        this.targetElement = _options.targetElement

        if (!this.targetElement) {
            console.warn('Missing \'targetElement\' property')
            return
        }

        this.time = new Time()
        this.sizes = new Sizes()
        this.setConfig()
        this.setDebug()
        this.setStats()
        this.setScene()
        this.setCamera()
        this.setRenderer()
        this.setResources()
        this.setWorld()
        this.setMouse()
this.setSound()

        this.sizes.on('resize', () => {
            this.resize()
        })

        this.update()
    }

    setConfig() {
        this.config = {}

        // Debug
        this.config.debug = window.location.hash === '#debug'

        // Pixel ratio
        this.config.pixelRatio = Math.min(Math.max(window.devicePixelRatio, 1), 2)

        // Width and height
        const boundings = this.targetElement.getBoundingClientRect()
        this.config.width = boundings.width
        this.config.height = boundings.height || window.innerHeight
    }

    setDebug() {
        if (this.config.debug) {
            this.debug = new GUI()
        }
    }

    setStats() {
        if (this.config.debug) {
            this.stats = new Stats(true)
        }
    }

    setScene() {
        this.scene = new THREE.Scene()
    }

    setCamera() {
        this.camera = new Camera()
    }

    setRenderer() {
        this.renderer = new Renderer({ rendererInstance: this.rendererInstance })

        this.targetElement.appendChild(this.renderer.instance.domElement)
    }

    setResources() {
        this.resources = new Resources(assets)
    }

    setWorld() {
        this.world = new World()
    }

    setMouse() {
        this.mouse = new Mouse()
    }

    update() {
        if (this.stats)
            this.stats.update()

        this.camera.update()

        if (this.world)
            this.world.update()

        if (this.renderer)
            this.renderer.update()

        window.requestAnimationFrame(() => {
            this.update()
        })
    }


    resize() {
        // Config
        const boundings = this.targetElement.getBoundingClientRect()
        this.config.width = boundings.width
        this.config.height = boundings.height

        this.config.pixelRatio = Math.min(Math.max(window.devicePixelRatio, 1), 2)

        if (this.camera)
            this.camera.resize()

        if (this.renderer)
            this.renderer.resize()

        if (this.world)
            this.world.resize()
    }


    setSound() {
        var listener = new THREE.AudioListener();
         
    this.camera.instance.add( listener );
       let  sound = new THREE.Audio(listener);
    
        this.audioLoader = new THREE.AudioLoader();
    
        this.audioLoader.load(Sound, function(buffer) {
            sound.setBuffer(buffer);
            sound.play();
        }, function(xhr) {
            console.log((xhr.loaded / xhr.total * 100) + '% loaded');
        }, function(err) {
            console.error('An error happened', err);
        });
    }
    
    
    destroy() {

    } 
}

// export default class Experience {
//     static instance

//     constructor(_options = {}) {
//         if (Experience.instance) {
//             return Experience.instance;
//         }
//         Experience.instance = this;

//         this.targetElement = _options.targetElement;

//         if (!this.targetElement) {
//             console.warn('Missing \'targetElement\' property');
//             return;
//         }

//         this.time = new Time();
//         this.sizes = new Sizes();
//         this.setConfig();
//         this.setDebug();
//         this.setStats();
//         this.setScene();
//         this.setCamera();
//         this.setRenderer();
//         this.setResources();
//         this.setWorld();
//         this.setMouse();

//         this.sizes.on('resize', () => {
//             this.resize();
//         });

//         this.update();
//     }

//     setConfig() {
//         this.config = {};

//         this.config.debug = window.location.hash === '#debug';
//         this.config.pixelRatio = Math.min(Math.max(window.devicePixelRatio, 1), 2);

//         const boundings = this.targetElement.getBoundingClientRect();
//         this.config.width = boundings.width;
//         this.config.height = boundings.height || window.innerHeight;
//     }

//     setDebug() {
//         if (this.config.debug) {
//             this.debug = new GUI();
//         }
//     }

//     setStats() {
//         if (this.config.debug) {
//             this.stats = new Stats(true);
//         }
//     }

//     setScene() {
//         this.scene = new THREE.Scene();
//     }

//     setCamera() {
//         this.camera = new Camera();
//     }

//     setRenderer() {
//         this.renderer = new Renderer({ rendererInstance: this.rendererInstance });
//         this.targetElement.appendChild(this.renderer.instance.domElement);

//         // Post-processing setup
//         this.composer = new EffectComposer(this.renderer.instance);
//         this.composer.addPass(new RenderPass(this.scene, this.camera.instance));

//         this.renderPass = new THREE.WebGLRenderTarget(this.config.width, this.config.height);
//         this.mountainMask = new THREE.WebGLRenderTarget(this.config.width, this.config.height);
    
//         this.planeComposer = new THREE.Mesh(new THREE.PlaneGeometry(2, 2), new THREE.ShaderMaterial({ 
//             uniforms: {
//                 tDiffuse: { value: this.renderPass.texture },
//                 uPatternTexture: { value: new THREE.TextureLoader().load(texture2) },
//                 uMountainMaskTexture: { value: this.mountainMask.texture }
//             },
//             vertexShader:` 
//             varying vec2 vUv;

//             void main() {
//                 vUv = uv;
//                 gl_Position = vec4(position, 1.0);
//             }`
//             ,
//             fragmentShader: `
//             uniform sampler2D tDiffuse;
//             uniform sampler2D uPatternTexture;
//             uniform sampler2D uMountainMaskTexture;

//             varying vec2 vUv;

//             void main() {
//                 vec4 mountainMask = texture2D(uMountainMaskTexture, vUv);

//                 vec4 sceneColor = texture2D(tDiffuse, vUv);
//                 vec4 patternColor = texture2D(uPatternTexture, vUv);

//                 sceneColor *= 1.0 - mountainMask.g;
//                 patternColor *= mountainMask.g;

//                 gl_FragColor = sceneColor + patternColor;
//             }
//             `
//          }));

//          this.planeComposer.layers.set(2);
//          this.scene.add(this.planeComposer);
//     }

//     setResources() {
//         this.resources = new Resources(assets);
//     }

//     setWorld() {
//         this.world = new World();
//     }

//     setMouse() {
//         this.mouse = new Mouse();
//     }

//     update() {

//         if (this.stats) this.stats.update();
//         this.camera.update();
//         if (this.world) this.world.update();

//         const clearColor = new THREE.Color()
//         const blackColor = new THREE.Color(0, 0, 0)
//         this.renderer.instance.getClearColor(clearColor);

//         // // Prepare base scene
//         // Mountain.mountains.forEach((mountain) => {
//         // mountain.mesh.layers.set(1);
//         //     mountain.setMaterial("maskMaterial");
//         // })

//         // // render to mountain mask
//         // this.renderer.instance.setRenderTarget(this.mountainMask);
//         // this.renderer.camera.instance.layers.set(0);
//         // this.renderer.update()

//         // this.renderer.instance.setClearColor(blackColor);
//         // this.renderer.camera.instance.layers.set(1);

//         // this.renderer.instance.autoClear = false
//         // this.renderer.instance.clear(true, false, true);
//         // this.renderer.update();
//         // this.renderer.instance.autoClear = true

//         // // Reset base scene
//         // Mountain.mountains.forEach((mountain) => {
//         //     mountain.mesh.layers.set(0);
//         //     mountain.setMaterial("material");
//         // })
//         // this.renderer.instance.setClearColor(clearColor);
//         // this.renderer.camera.instance.layers.set(0);
//         // this.renderer.instance.setRenderTarget(null);

//         // // render to renderpasss
//         // this.renderer.instance.setRenderTarget(this.renderPass);
//         // this.renderer.update();
//         // this.renderer.instance.setRenderTarget(null);

//         // // render to screen
//         // this.renderer.camera.instance.layers.set(2);
//         this.renderer.update();

//         window.requestAnimationFrame(() => {
//             this.update();
//         });
//     }

//     resize() {
//         const boundings = this.targetElement.getBoundingClientRect();
//         this.config.width = boundings.width;
//         this.config.height = boundings.height;

//         this.config.pixelRatio = Math.min(Math.max(window.devicePixelRatio, 1), 2);

//         if (this.camera) this.camera.resize();
//         if (this.renderer) this.renderer.resize();
//         if (this.world) this.world.resize();

       
//         this.composer.setSize(this.config.width, this.config.height);
//     }

//     destroy() {
//         // Cleanup resources if necessary
//     }
// }



