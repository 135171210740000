import * as THREE from 'three';
import Entity from "./Entity";
import { Float32BufferAttribute } from "three";
import SkyMaterial from "../Materials/SkyMaterial";

export default class Sky extends Entity {
  params;
  vertices = [];
  uvs = [];
  indices = [];
  normals = [];

  constructor(params = {
    width: window.innerWidth*1.5,
    height: window.innerHeight*1.5,
    widthSegments: 200,
    heightSegments: 200,
  }) {
    super();
    this.params = params;
    this.setModel();
  }

  setModel() {

    this.geometry = new THREE.PlaneGeometry(this.params.width, this.params.height, this.params.widthSegments, this.params.heightSegments);

    this.cloudAppear = Math.floor(this.prng()*4)
    this.cloudAppear = this.cloudAppear >= 1;


    this.material = new SkyMaterial({
      color: "#44CCFF",
      side: THREE.DoubleSide,
    },
      this.time,
        {
          uPattern: new THREE.CanvasTexture(this.world.canvasSetup.canvases["sky"].cvs),
          uResolution: new THREE.Vector2( this.sizes.viewport.width, this.sizes.viewport.height),
          uColorSky : this.world.color3,
          uHumidity : this.world.humidity,
          uRandClouds : this.prng(),
          uCloudAppear : this.cloudAppear

        }
    );

    this.mesh = new THREE.Mesh(this.geometry, this.material);

    this.mesh.rotation.z = Math.PI / 2;

    this.mesh.position.z = -1000;
    this.scene.add(this.mesh);
  }

  update(){
    this.material.update()
  }
}
