import * as THREE from 'three'
import Entity from "./Entity";
import LeafMaterial from "../Materials/LeafMaterial";
import RockMaterial from "../Materials/RockMaterial";
import LanternMaterial from "../Materials/LanternMaterial";
import HouseMaterial from "../Materials/HouseMaterial";
import SkyMaterial from "../Materials/SkyMaterial";
import LightMaterial from "../Materials/LightMaterial";

export default class LightSphere extends Entity
{

    constructor(position)
    {
        super();
        this.position = position;

        this.setModel()

    }

    setModel(){
        this.material = new LightMaterial({
                color: "#44CCFF",
                side: THREE.DoubleSide,
            },
            this.time,
            {
                uPattern: new THREE.CanvasTexture(this.world.canvasSetup.canvases["sky"].cvs),
                uResolution: new THREE.Vector2( this.sizes.viewport.width, this.sizes.viewport.height),
                uColorSun : this.world.color4
            }
        );
        this.geometry = new THREE.SphereGeometry( 25, 32, 16 ) ;

        this.pLight = new THREE.PointLight(this.world.color4, this.world.lightStrength * 150);
        this.pLight.castShadow = true;
        this.pLight.shadow.mapSize.width = 2048; // Résolution de la carte d'ombres en largeur
        this.pLight.shadow.mapSize.height = 2048;
        this.scene.add(this.pLight)

        this.mesh = new THREE.Mesh(this.geometry,this.material);

        this.pLight.position.set(this.position.x, this.position.y,this.position.z);
        this.mesh.position.set(this.position.x, this.position.y,this.position.z);

        this.scene.add(this.mesh)

    }




    update()
    {
        if(this.material){this.material.update();}
    }
}