export default [
    {
        name: 'base',
        data: {},
        items:
            [
                {name: 'lennaTexture', source: '/assets/lenna.png', type: 'texture'},
                {name: 'bambooBush', source: '/assets/bamboo_bush.glb'},
                {name: 'lantern', source: '/assets/lantern.glb'},
                {name: 'house', source: '/assets/house.glb'},
            ]
    }
]


