import * as THREE from 'three'
import Entity from "./Entity";
import GrassMaterial from "../Materials/GrassMaterial";

export default class Grass extends Entity
{
    static idx = 0;

    constructor()
    {
        super();
        this.setModel()

    }

    setModel(){
        this.selectedPoints = this.getPointsInThreshold()
        const nbPoints = this.selectedPoints[0].length

        if(this.selectedPoints[0].length > 0){

            const dummy = new THREE.Object3D();
            this.geometry = new THREE.PlaneGeometry(0.025,0.5,1,3);
            this.geometry.computeBoundingBox()
            this.geometry.translate(
                (this.prng()-.5)*.25,
                + this.geometry.boundingBox.max.y * 1,
                (this.prng()-.5)*.25
            )

            const randomValues = []
            for (let i = 0; i < nbPoints; i++) {
                // Générez une valeur aléatoire unique pour chaque instance
                const randomValue = this.prng();
                randomValues.push(randomValue);
            }

            this.geometry.setAttribute('aRandom', new THREE.Float32BufferAttribute(randomValues, 1));


            this.material =new GrassMaterial({color:"#702713",transparent : true},this.time,
                {
                    uMinVal: this.world.plain.minVal,
                    uMaxVal: this.world.plain.maxVal,
                    uGrassColor:this.world.color1,
                    uSandColor: this.world.color2,
                    uHumidity: this.world.humidity,
                    uPattern: new THREE.CanvasTexture(this.world.canvasSetup.canvases["grass"].cvs)

                });

            this.mesh = new THREE.InstancedMesh(this.geometry, this.material,nbPoints)

            for(let i = 0; i < nbPoints; i++){
                dummy.position.set(...this.selectedPoints[0][i])
                // dummy.lookAt(this.camera.position)
                dummy.updateMatrix()
                this.mesh.setMatrixAt(i, dummy.matrix)
            }
            this.scene.add(this.mesh);


        }

    }

    getPointsInThreshold(){
        let vertices = [...this.world.plain.points];
        const threshold = this.prng() * (1.25-0.75) + 0.75;
        vertices =  vertices.filter(point => {
            return (this.calcThreshold(point[1]) > threshold)
        } )

        const newVertices = [];
        const newVerticesDest = [];

        for(let i = 0; i < vertices.length;i++){
            for(let j = 0; j <= 20; j++){
                const newX = vertices[i][0] + (this.prng()-.5) *.5
                const newZ = vertices[i][2] + (this.prng()-.5) *.5
                newVertices.push(this.world.plain.extractCoord(newX, newZ))
                newVerticesDest.push(...this.world.plain.extractCoord(newX, newZ).toArray())
            }
        }
        return [newVertices,newVerticesDest]
    }

    calcThreshold(y){
        return (y - this.world.plain.minVal)*this.world.humidity/this.world.plain.maxVal;
    }


    update()
    {
        if(this.material){
            this.material.update()
        }
    }
}